<template>
  <div class="info-box">
    <v-title title="Endorsement Type"></v-title>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <div
          class="form-item form-half ">
          <el-form-item
            ref="ref_number"
            prop="ref_number"
          >
            <v-checkbox-group
              v-model="formData.tags"
              :list="claimVipList"
            ></v-checkbox-group>
          </el-form-item>
        </div>
        <v-row-col
          :same="true"
          :same-number="12">
          <div class="form-item ">
            <span>Endorsement Effective Date</span>
            <el-form-item prop="endorsement_effective_date">
              <v-date-picker
                v-model="formData.endorsement_effective_date"
                @change="handlerComputed"
              ></v-date-picker>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div
            class="form-item">
            <el-form-item>
              <v-checkbox-group
                v-model="formData.endorsement_type"
                :list="
                  endorsementType"
              ></v-checkbox-group>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="form-item">
            <span>Notes (Internal)</span>
            <el-form-item prop="notes">
              <v-input
                v-model="formData.notes"
                type="textarea"
                :rows="4"></v-input>
            </el-form-item>
          </div>
        </v-row-col>

      </el-row>
      <slot name="form"></slot>
      <el-row
        class="row-bg flex-inline"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <v-title title="Endorsement Premium"></v-title>
          <div v-if="!isHelper&&!isHome&&!isEmployee">
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span class="disable-span">Original Annual Gross Premium</span>
                <el-form-item >
                  <v-input
                    v-model.number="showData.original_gross_premium"
                    prepend="HKD"
                    disabled/>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span  class="disable-span">Original Annual Premium</span>
                <el-form-item >
                  <v-input
                    v-model.number="showData.original_premium"
                    prepend="HKD"
                    disabled/>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span  class="disable-span">Original Annual IA Levy {{refundName}}</span>
                <el-form-item>
                  <v-input
                    v-model.number="showData.original_ia_mib_ec"
                    prepend="HKD"
                    disabled/>
                </el-form-item>
              </div>
            </v-row-col>
          </div>
          <div v-else>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span class="disable-span">Original Premium</span>
                <el-form-item >
                  <v-input
                    v-model.number="showData.original_premium"
                    prepend="HKD"
                    disabled/>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span  class="disable-span">IA Levy</span>
                <el-form-item >
                  <v-input
                    v-model.number="showData.ia_levy_vl"
                    prepend="HKD"
                    disabled/>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              v-if="isHelper||isEmployee"
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span  class="disable-span">EC Levy</span>
                <el-form-item >
                  <v-input
                    v-model.number="showData.ec_levy_vl"
                    prepend="HKD"
                    disabled/>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span class="disable-span">Original Gross Premium</span>
                <el-form-item >
                  <v-input
                    v-model.number="showData.original_gross_premium"
                    prepend="HKD"
                    disabled/>
                </el-form-item>
              </div>
            </v-row-col>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
      </el-row>
      <el-row
        class="row-bg flex-inline"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>{{productMotor?'Base':''}} Premium</span>
              <el-form-item prop="base_premium">
                <v-input
                  v-model.number="formData.base_premium"
                  prepend="HKD"
                  :disabled="isHome"
                  @change="handlerComputed"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="productMotor"
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Client Discount</span>
              <el-form-item prop="client_discount">
                <v-input
                  v-model.number="formData.client_discount"
                  append="%"
                  @change="handlerComputed"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="productMotor"
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Loading</span>
              <el-form-item prop="loading">
                <v-input
                  v-model.number="formData.loading"
                  append="%"
                  @change="handlerComputed"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="productMotor"
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span>NCD</span>
              <el-form-item prop="ncd">
                <v-select
                  v-model="formData.ncd"
                  :list="ncdList"
                  @change="handlerChangeNcd"
                />
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            v-if="productMotor"
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Basic Premium</span>
              <el-form-item>
                <v-input
                  v-model.number="showData.basic_premium"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="productMotor"
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Additional Premium</span>
              <el-form-item prop="additional_premium">
                <v-input
                  v-model.number="formData.additional_premium"
                  prepend="HKD"
                  @change="handlerComputed"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="productMotor"
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>IA Levy</span>
              <el-form-item prop="ia_levy">
                <div class="flex-aligin flex">
                  <v-input
                    v-model.number="formData.ia_levy"
                    append="%"
                    :disabled="formData.is_ia_levy==0"
                    @change="handlerComputed"/>
                  <v-checkbox
                    v-model="formData.is_ia_levy"
                    @change="handlerCheck"></v-checkbox>
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-else
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>IA Levy</span>
              <el-form-item prop="ia_levy_vl">
                <div class="flex-aligin flex">
                  <v-input
                    v-model.number="formData.ia_levy_vl"
                    prepend="HKD"
                    :disabled="formData.is_ia_levy_vl==0"
                    @change="handlerComputed"/>
                  <v-checkbox
                    v-model="formData.is_ia_levy_vl"
                    @change="handlerCheck"></v-checkbox>
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="showEcPercent"
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>EC Levy %</span>
              <el-form-item prop="ec_levy">
                <v-input
                  v-model.number="formData.ec_levy"
                  append="%"
                  @change="handlerComputed"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="showEcHKD"
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>EC Levy </span>
              <el-form-item prop="ec_levy_vl">
                <div class="flex-aligin flex">
                  <v-input
                    v-model.number= "formData.ec_levy_vl"
                    prepend="HKD"
                    :disabled="formData.is_ec_levy_vl==0"
                    @change="handlerComputed"
                  />
                  <v-checkbox
                    v-model="formData.is_ec_levy_vl"
                    @change="handlerCheck"></v-checkbox>
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="productMotor"
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">MIB</span>
              <el-form-item >

                <div class="flex-aligin flex">
                  <v-input
                    v-model.number= "formData.mib_vl"
                    prepend="HKD"
                    :disabled="formData.is_mib_vl==0"
                    @change="handlerComputed"
                  />
                  <v-checkbox
                    v-model="formData.is_mib_vl"
                    @change="handlerCheck"></v-checkbox>
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Total Premium</span>
              <el-form-item>
                <v-input
                  v-model.number= "showData.total_premium"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Endorsement Effective Date</span>
              <el-form-item>
                <v-date-picker
                  v-model="formData.endorsement_effective_date"
                  disabled></v-date-picker>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Gross Premium Difference</span>
              <el-form-item>
                <v-input
                  v-model.number= "showData.gross_premium_difference"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Gross Premium Difference without IA Levy {{refundName}}</span>
              <el-form-item>
                <v-input
                  v-model.number= "showData.basic_premium_difference"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Endorsement IA Levy  {{refundName}}</span>
              <el-form-item>
                <v-input
                  v-model.number= "showData.endorsement_ia_mib_ec"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
      </el-row>
      <el-row
        class="row-bg flex-inline"
        :gutter="30"
        justify="space-around">
        <v-row-col
          :same="true"
          :same-number="24"
        >
          <v-row-col
          >
            <div class="flex-center mg-b-16">
              <el-checkbox
                v-model="isDiscount"
                :is-upper="false"
                @change="handlerChangeBox('isDiscount','isAmount',1)"></el-checkbox>

              <div class="form-item mg-l-5 width-fill mg-b-0">
                <span>Special Discount %</span>
                <el-form-item prop="base_premium">
                  <v-input
                    v-model.number="formData.special_discount"
                    append="%"
                    :disabled="isAmount"
                    @change="handlerComputed"/>
                </el-form-item>
              </div>
            </div>
          </v-row-col>
          <v-row-col
          >
            <div class="form-item ">
              <span  class="disable-span">Special Discount Amount</span>
              <el-form-item>
                <v-input
                  v-model="showData.special_discount_vl"
                  prepend="HKD"
                  disabled
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
          >
            <div class="form-item ">
              <span  class="disable-span">Premium Difference</span>
              <el-form-item>
                <v-input
                  v-model.number="showData.premium_difference"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="!isHome"
          >
            <div class="form-item ">
              <span  class="disable-span">IA Levy {{ refundName }}</span>
              <el-form-item>
                <v-input
                  v-model.number="showData.endorsement_ia_mib_ec"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="isHome"
          >
            <div class="form-item ">
              <span  class="disable-span">IA Levy</span>
              <el-form-item>
                <v-input
                  v-model.number="showData.endorsement_ia_mib"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="isHome"
          >
            <div class="form-item ">
              <span  class="disable-span">EC Levy</span>
              <el-form-item>
                <v-input
                  v-model.number="showData.endorsement_ec"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
      </el-row>
      <el-row
        class="row-bg flex-inline"
        :gutter="30"
        justify="space-around">

        <!--        <v-row-col-->

        <!--        >-->
        <v-row-col
          :same="true"
          :same-number="8">
          <div class="flex-center mg-b-16">
            <el-checkbox
              v-model="isAmount"
              @change="handlerChangeBox('isAmount','isDiscount',2)"></el-checkbox>
            <div class="form-item mg-l-5 width-fill mg-b-0">
              <span>Amount Received</span>
              <el-form-item>
                <v-input
                  v-model.number= "formData.amount_received_without "
                  prepend="HKD"
                  :disabled="isDiscount"
                  @change="handlerComputed"
                />
              </el-form-item>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="8">
          <div class="form-item ">
            <span>Handling Charge</span>
            <el-form-item
              ref="handing_charge"
              prop="handing_charge">
              <v-input
                v-model.number= "formData.handing_charge"
                prepend="HKD"
                @change="handlerComputed"
              />
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="8">
          <div class="form-item ">
            <span  class="disable-span">Endorsement Total Payable</span>
            <el-form-item>
              <v-input
                v-model.number= "showData.endorsement_total_payable"
                prepend="HKD"
                disabled/>
            </el-form-item>
          </div>
        </v-row-col>
        <!--        </v-row-col>-->
        <v-row-col  class="pd-l-0">


        </v-row-col>
        <v-row-col  class="">

        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
      </el-row>
      <el-row
        class="row-bg flex-inline"
        :gutter="30"
        justify="space-around">
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="flex-center mg-b-16">
              <el-checkbox
                v-model="is_net"
                @change="handlerNet(1,'is_net','is_net_val')"></el-checkbox>
              <div class="form-item mg-l-5 width-fill mg-b-0">
                <span>Net %</span>
                <el-form-item prop="base_premium">
                  <v-input
                    v-model.number="formData.net"
                    append="%"
                    :disabled="formData.is_net==2"
                    @change="handlerComputed"/>
                </el-form-item>
              </div>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="flex-center mg-b-16">
              <el-checkbox
                v-model="is_net_val"
                @change="handlerNet(2,'is_net_val','is_net')"></el-checkbox>
              <div class="form-item mg-l-5 width-fill mg-b-0">

                <span  class="disable-span">Net</span>
                <el-form-item >
                  <v-input
                    v-model.number="formData.net_vl"
                    prepend="HKD"
                    :disabled="formData.is_net==1"
                    @change="handlerComputed"
                  />
                </el-form-item>
              </div>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Net Comm.$</span>
              <el-form-item>
                <v-input
                  v-model.number= "showData.net_commission_vl"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Insurer Net %</span>
              <el-form-item>
                <v-input
                  v-model="showData.insurer_net"
                  append="%"
                  disabled
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Insurer Net</span>
              <el-form-item>
                <v-input
                  v-model.number="showData.insurer_net_vl"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Insurer Net Comm.$</span>
              <el-form-item>
                <v-input
                  v-model.number="showData.insurer_net_commission_vl"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">Referrer Comm.</span>
              <el-form-item >
                <v-input
                  v-model.number="showData.referrer_commission_vl"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span  class="disable-span">RM Comm.</span>
              <el-form-item>
                <v-input
                  v-model.number="showData.owner_commission_vl"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {computedEndorsement} from '@api/endorsement'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'endorsement',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    }
  },

  data(){
    return{
      is_self:true,
      is_ia_levy_vl:0,
      is_ia_levy:0,
      is_ec_levy_vl:0,
      is_net:true,
      is_net_val:false,
      isDiscount:true,
      isAmount:false,
      showData:{
        original_ia_mib_ec:'',
        endorsement_ia_mib:'',
        endorsement_ec:'',
        original_premium:'',
        original_gross_premium:'',
        gross_premium_difference:'',
        ncd:'',
        mib_vl:'',
        ia_levy_vl: '',
        ec_levy_vl: '',
        amount_received: '',
        gross_premium:'',
        total_premium:'',
        net_vl:'',
        owner_commission_vl:'',
        insurer_net_commission_vl:'',
        insurer_net_vl:'',
        net_commission_vl:'',
        referrer_commission_vl:'',
        insurer_net: '',
        basic_premium:'',
        endorsement_total_payable:'',
        premium_difference:'',
        basic_premium_difference:'',
        endorsement_ia_mib_ec:'',
        special_discount_vl:''
      },
      formData:{
        ncd:'',
        handing_charge:'',
        base_premium:'',
        loading:'',
        client_discount:'',
        additional_premium:'',
        ia_levy:'',
        tags:[],
        special_discount:'',
        amount_received_without:'',
        net:'',
        endorsement_effective_date:'',
        endorsement_type:[],
        notes:'',
        is_edit:0,
        ec_levy_vl:'',
        ec_levy:'',
        is_ec_levy_vl:0,
        is_ec_levy:0,
        is_ia_levy_vl:0,
        is_ia_levy:0,
        is_net:1,
        is_special:1,
        net_vl:'',
        is_mib_vl:0,
        ia_levy_vl:0,
        domestic_helpers_premium:'',
        c_e_c_premium:''
      },
    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm','ncd','ncdList']),
    ...mapState('coverage', ['domesticPremium','homePremium','constructionPremium']),

    order_no(){
      let {order_no=''} = this.quoteInformationForm
      return order_no
    },
    productId(){
      return this.form.product_id
    },
    isHelper(){
      return this.productId == 16
    },
    isHome(){
      return this.productId == 13
    },
    isEmployee(){
      return this.productId == 14
    },
    productMotor(){
      return this.productId == 10
    },
    showEcPercent(){
      // return this.productId==13
      return false
    },
    showEcHKD(){
      return [13,14,15,16,17,18].includes(this.productId)
    },
    refundName(){
      if(this.productId==10)
        return  ' MIB  '
      if(this.productId===12)
        return  ''
      else  return  'and EC Levy'
    },
    isTravel(){
      return this.productId==12
    },
    endorsementType()
    {
      let typesArr = this.globalOptions.endorsement_type
      let arr = []
      typesArr.filter(item=>{
        if(item.product_id == this.productId)
          arr = item.items
      })
      return arr.length<=0?[]:arr
    },
    is_effective(){
      let {order_status} = this.quoteInformationForm||{}
      return order_status>10&&!this.$route.query.isEndorsement
    },
    isEndorsement(){
      let {isEndorsement} = this.$route.query||{}
      return isEndorsement=='true'
    },
  },
  watch:{
    ncd:{
      immediate:true,
      handler(val,old){
        this.formData.ncd = val||''
        if(old>=0&&!this.is_self)
        {
          this.is_self = false
          this.handlerComputed()
        }
      }
    },
    domesticPremium:{
      immediate: true,
      handler(val,old) {
        if(val&&this.isHome)
        {
          let {domestic_helpers_premium=0}=val||{}
          this.formData.domestic_helpers_premium = domestic_helpers_premium||0
          this.handlerComputed()
        }
      }
    },
    homePremium:{
      immediate: true,
      handler(val,old) {
        if(val&&this.isHome)
        {
          let {home_contents_all_risk=0,personal_accident=0,personal_liability=0,domestic_helpers_premium=0,other_premium=0,building_premium=0} =val||{}
          let premium = home_contents_all_risk+personal_accident+personal_liability+domestic_helpers_premium+other_premium+building_premium
          this.formData.base_premium= premium
          this.handlerComputed()
        }
      }
    },
    constructionPremium:{
      immediate: true,
      handler(val,old) {
        if(val&&this.productID==17)
        {
          this.formData.c_e_c_premium= val||0
          this.handlerComputed()
        }
      }
    },
  },
  mounted() {
    this.handlerInit()
  },
  methods:{
    ...mapActions('quotation', ['setNcd']),
    handlerChangeBox(name1,name2,num){
      this[name1] = true
      this[name2] =false
      this.formData.is_special = num
      this.is_special = num
      setTimeout(()=>{
        this.handlerComputed()
      },500)
    },
    handlerNet(val,name1,name2){
      this.formData.is_net = val
      this[name1] = true
      this[name2] = false
      // this.handlerComputed()
    },
    handlerCheck(){
      // this.formData.ia_levy_vl = this.formData.is_ia_levy_vl? this.formData.ia_levy_vl:0
      // this.formData.ec_levy_vl = this.formData.is_ec_levy_vl? this.formData.ec_levy_vl:0
      // this.formData.ia_levy = this.formData.is_ia_levy? this.formData.ia_levy:0
      this.handlerComputed()
    },
    handlerInit(){
      if(this.quoteInformationForm){
        let {premium,notes,endorsement_type=[],endorsement_effective_date='',is_edit=0,coverage,tags} = this.quoteInformationForm
        let {optional_benefit}=coverage||{}
        let {domestic_helpers_premium} = optional_benefit||{}

        this.showData = Object.assign(this.showData,premium)
        this.formData.endorsement_type = endorsement_type
        this.formData.notes = notes
        this.formData.is_edit =is_edit
        this.formData.tags =tags

        let {ec_levy_vl=0,ia_levy_vl=0,is_net=1,is_special} = premium||{}
        this.is_ec_levy_vl = ec_levy_vl>0?1:0
        this.is_ia_levy_vl = ia_levy_vl>0?1:0
        this.is_net = is_net==1
        this.is_net_val = is_net==2
        this.formData.is_net = is_net
        if( !is_special)
        {
          this.formData.is_special =1
          this.is_special =1
          this.isAmount = false
          this.isDiscount=true
        }
        this.isAmount = is_special==2
        this.isDiscount = is_special==1
        if(is_edit)
          this.formData.endorsement_effective_date = endorsement_effective_date
        Object.keys(this.formData).some(key => {
          if (key in premium) {
            this.formData[key] = premium[key]
          }
        })
        if(this.isHome){
          this.formData.domestic_helpers_premium = domestic_helpers_premium||0
        }
        this.is_self = true
        if(this.isEndorsement){
          this.isDiscount = true
          this.isAmount =false
          this.is_special =1
          this.formData.is_special=1
          this.is_net = true
          this.is_net_val =false
          this.formData.is_net =1
        }
        this.network().computedQuotation()
      }
    },
    async submitForm(formName) {
      await new Promise((resolve, reject) => {
        this.$refs['form'].validate(valid => {
          if(valid) {
            this.$emit('getFormData', formName, this.formData)
            resolve(valid)
          } else {
            reject(valid)
          }
        })
      })
    },
    handlerChangeNcd(){
      this.is_self= false
      this.setNcd(this.formData.ncd)
    },
    handlerComputed(){
      // this.$refs.form.validate((valid, invalidFields) => {
      //   if (valid) {
      this.network().computedQuotation()
      // }else{
      // }
      // })
    },
    network() {
      return {
        computedQuotation:async ()=>{
          this.is_self= false
          let params ={...this.formData,...{order_no:this.order_no,product_id:this.form.product_id}}
          let { data } = await computedEndorsement(params)
          this.showData = data
          let {endorsement_effective_date,handing_charge} = data
          this.formData.endorsement_effective_date = endorsement_effective_date
          this.formData.handing_charge = handing_charge
          Object.keys(this.formData).some(key => {
            if (key in data) {
              this.formData[key] = data[key]
            }
          })
          this.setNcd(this.formData.ncd)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.flex-inline{
  .form-item{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    span{
      flex: 1;
    }
    .disable-span{
      color: #718096;
    }
    .el-form-item{
      flex: 2;
    }

  }
  .hr-inline{
    margin:20px 10px;
  }
}
.width-fill{
  width: 100%;
}
.pd-l-0{
  padding-left: 0!important;
}
</style>
